<template>
  <div v-if="cntSelBill!=1" class="container-fluid">{{ $t('gamebill.gamesbill.chooseOneAccount') }}</div>
  <div v-if="cntSelBill==1 && rows.length==0 && !is_sel" class="container-fluid">
    <p>{{ $t('gamebill.gamesbill.noGamesFor') }}</p>
  </div>
  <div class="container-fluid" v-if="cntSelBill==1 && rows.length>0">
    <div v-if="isaadj=='close'">
      <p></p>
      <vue-good-table ref="table" v-if="!is_sel"
          styleClass="vgt-table bordered vgt-compact row_color_even_odd row_color_hover"
          :columns="columns"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            perPage: 100,
            perPageDropdown: perPageDropdown,
            nextLabel: $t('app.vue-good-table-next.nextLabel'),
            prevLabel: $t('app.vue-good-table-next.prevLabel'),
            rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
            ofLabel: $t('app.vue-good-table-next.ofLabel'),
            pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
            allLabel: $t('app.vue-good-table-next.allLabel')
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'id', type: 'asc'}
          }"
          compactMode>
        <template #emptystate>
          <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
        </template>
        <template #table-row="props">
            <input v-if="props.column.field == 'isselect'" :disabled="!sel_games_display_in_admin.includes(props.row.id)" @change="change_active(props.row.id)" name="sel_games" type="checkbox" :value="props.row.id" v-model="sel_games">
            <input v-if="props.column.field == 'display_in_cashier'" :disabled="!sel_games.includes(props.row.id)" name="sel_games_display_in_cashier" type="checkbox" :value="props.row.id" v-model="sel_games_display_in_cashier">
            <input v-if="props.column.field == 'display_in_admin'"
                   @change="change_visible(props.row.id)"
                   :disabled="!sel_games_display_in_admin.includes(props.row.id) && (is_super===true || is_admin_sb===true) && disabledRow.includes(props.row.id)"
                   :readonly="disabledRow.includes(props.row.id)"
                   name="sel_games_display_in_admin"
                   type="checkbox"
                   :value="props.row.id"
                   v-model="sel_games_display_in_admin">
            <GameBillActions v-if="props.column.field == 'jackpot' && props.row.isselect=='1'" :selRow="props.row"></GameBillActions>
        </template>
      </vue-good-table>
      <br>
      <button @click="saveGamesBill" :disabled="disBut" class="btn-success">{{ $t('gamebill.gamesbill.save') }}</button>
  ` </div>
    <div v-if="isaadj!='close'">
      <JackpotsEdit></JackpotsEdit>
    </div>
  </div>
</template>

<script setup>
import GameService from "../../services/game.service";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {computed, defineEmits, inject, ref, onUnmounted, provide, watch} from "vue";
import {useStore} from "vuex";
import GameBillActions from "./GameBillActions.vue";
import JackpotsEdit from "../Jackpots/JackpotsEdit.vue";

const sortByInt = inject('sortByInt')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const store = useStore()

const emit = defineEmits(['selRow', 'onGetRows'])

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const set_result = inject('set_result')

const disBut = ref(false)

const game_id = ref('')
provide('game_id', game_id)

const grade_next = ref('')
provide('grade_next', grade_next)

const isaadj = ref('close')
provide('isaadj', isaadj)

function addJackpots(aj, gid='', jid='', grade='')
{
  isaadj.value=aj
  if(isaadj.value!='close') {
    game_id.value=gid.id
    jackpot_id.value=jid

    grade_next.value=gid.nextGrade
    if(grade!='') {
      grade_next.value=grade
    }

  } else {
    game_id.value=''
    grade_next.value=''
    jackpot_id.value=''
  }
}
provide('addJackpots', addJackpots)

const jackpot_id = ref('')
provide('jackpot_id', jackpot_id)

const content = ref("")
const is_sel = ref(false)
const sel_row = ref({})
const sel_games= ref([])
const sel_games_display_in_cashier= ref([])
const sel_games_display_in_admin= ref([])
const sel_bill = ref([])
const set_games = ref([])
const type = ref('')

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => {
  const baseColumns = [
    {
      label: $t('gamebill.gamesbill.number'),
      field: 'id',
      filterOptions: {enabled: true, placeholder: $t('gamebill.gamesbill.number')},
      width: '30px',
      sortFn: sortByInt,
    },
    {
      label: $t('gamebill.gamesbill.gameName'),
      field: 'game_name',
      filterOptions: {enabled: true, placeholder: $t('gamebill.gamesbill.gameName')},
      width: '250px',
    },
    {
      label: '🔗' ,
      field: 'isselect',
      filterOptions: {enabled: false, placeholder: $t('gamebill.gamesbill.linked')},
      width: '30px',
      tooltip: $t('gamebill.gamesbill.linked'),
    },
    {
      label: '👤',
      field: 'display_in_cashier',
      filterOptions: {enabled: false, placeholder: $t('gamebill.gamesbill.display_in_cashier')},
      width: '30px',
      tooltip: $t('gamebill.gamesbill.display_in_cashier'),
    },
    {
      label: '⚙️',
      field: 'display_in_admin',
      filterOptions: {enabled: false, placeholder: $t('gamebill.gamesbill.display_in_admin')},
      width: '30px',
      tooltip: $t('gamebill.gamesbill.display_in_admin'),
    },
    {
      label: $t('gamebill.gamesbill.jackpots'),
      field: 'jackpot',
      width: '170px'
    },
  ]

  if (is_super.value || is_admin_sb.value) {
    return baseColumns;
  } else {
    return baseColumns.filter(column => column.field !== 'display_in_admin')
  }
});

const rows = ref([])

const disabledRow = ref([])

function onSelRow(params)
{
  sel_row.value=params.sel_row
  is_sel.value=params.is_sel
  type.value=params.type
}

function getRows(selBill)
{
  if(selBill!=undefined) {
    set_result('off')
    sel_bill.value=selBill
    GameService.getGamesByBill(selBill).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        if (is_super.value==true || is_admin_sb.value==true) {
          rows.value = response.data
        } else {
          rows.value = response.data.filter((row) => row.display_in_admin !== 'false')
        }

        sel_games.value=[]
        sel_games_display_in_cashier.value=[]
        sel_games_display_in_admin.value=[]
        unvisibleGames.value=[]
        disabledRow.value=[]

        for(let key in rows.value) {

          if(rows.value[key].isselect==1)
          {
            sel_games.value.push(rows.value[key].id)
          }

          if(rows.value[key].display_in_cashier=='true')
          {
            sel_games_display_in_cashier.value.push(rows.value[key].id)
          }

          if(rows.value[key].display_in_admin!='false')
          {
            sel_games_display_in_admin.value.push(rows.value[key].id)
          } else {
            unvisibleGames.value.push({id: rows.value[key].id, display_in_admin: false})
          }
          if (rows.value[key].disabled==0){
            disabledRow.value.push(rows.value[key].id)

          }

          rows.value[key].jackpots=JSON.parse(rows.value[key].jackpots)
          rows.value[key].nextGrade=getNextGrade(rows.value[key].jackpots)
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function saveGamesBill()
{
  disBut.value = true;

  GameService.saveGamesByBill(sel_bill.value, sel_games.value, sel_games_display_in_cashier.value, unvisibleGames.value).then(
      () => {
        emit('onGetRows')
        emit('selRow', {is_edit: false})
        getDataBillGames()
        set_result()

        disBut.value = false;
      },
      (error) => {
        disBut.value = false;

        set_result(error.response.status, error)
      }
  )
}

function cntSelBillFn()
{
  let selBills=store.state.bill.selBills
  let cnt = selBills.length
  if(cnt==1)
  {
    getRows(selBills[0])
  }

  return cnt
}
const cntSelBill = computed(cntSelBillFn)

function getNextGrade(jackpots)
{
  let grade_list = ['BIG', 'SUPER', 'MEGA']

  let nextGrade='';

  for (let jackpot in jackpots) {
    let cur=jackpots[jackpot]
    if(cur.grade_code==grade_list[0]) {
      grade_list.shift()
    }
  }
  nextGrade=grade_list[0]

  return nextGrade;
}

function change_active(game_id)
{
  if(sel_games.value.includes(game_id)==true) {
    sel_games_display_in_cashier.value.push(game_id)
  } else {
    var myIndex = sel_games_display_in_cashier.value.indexOf(game_id);
    if (myIndex !== -1) {
      sel_games_display_in_cashier.value.splice(myIndex, 1)
    }
  }
}

const unvisibleGames = ref([])

function change_visible(game_id) {
  if(sel_games_display_in_admin.value.includes(game_id)!=true) {
    var myIndex = sel_games_display_in_cashier.value.indexOf(game_id);
    if (myIndex !== -1) {
      sel_games.value.splice(myIndex, 1)
      sel_games_display_in_cashier.value.splice(myIndex, 1)
    }
    unvisibleGames.value.push({id: game_id, display_in_admin: false})
  } else {
    const filteredGames = unvisibleGames.value.filter(game => game.id !== game_id);
    unvisibleGames.value = filteredGames;
  }
}

function getDataBillGames()
{
  let selBills=store.state.bill.selBills
  let cnt = selBills.length
  if(cnt==1)
  {
    getRows(selBills[0])
  }
}

provide('getDataBillGames', getDataBillGames)

onUnmounted(function ()
{
  set_result('off')
  getDataBillGames()
})
</script>
